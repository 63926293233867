import {
    Autocomplete,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    TextField,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { doSwaggerCall } from '../hooks/useApi';
import useNotify from '../hooks/useNotify';
import CommonButton from '../components/atom/CommonButton';
import {
    cutEsgTree,
    transformData,
} from '../pages/esgscenarios/helpers/targetPickerEsgHelperFunctions';
import recursiveChildrenForEsgScenario from '../pages/esgscenarios/helpers/recursiveChildrenForEsgScenario';
import { groupQuestion } from '../helpers/groups';

function AddEsgQuestionToScenarioModal({
    scenario,
    questionList,
    reloadData,
    showModal,
    MODAL_TYPE,
}) {
    const { notifySuccess, notifyError } = useNotify();
    const [dataGroups, setDataGroups] = useState([]);
    const [selectedTargetData, setSelectedTargetData] = useState(questionList || []);
    const [loading, setLoading] = useState(false);

    const loadQuestions = useCallback((baselineYear, siteIdList, tagId) => {
        setLoading(true);
        doSwaggerCall('EsgQuestions', 'getEsgQuestionHierarchy', {
            year: baselineYear,
            hasanyesgs: true, // show only data that have esgs
            siteIdList,
            esgTagId: tagId !== 0 ? tagId : null,
        })
            .then((hierarchy) => {
                setDataGroups(transformData(hierarchy));
                setLoading(false);
            })
            .catch((error) => {
                notifyError(error);
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        loadQuestions(scenario.baseline_year, scenario.site_filter, scenario.esg_tag_id);
    }, []);

    const saveScenario = async () => {
        try {
            const res = await doSwaggerCall('EsgQuestions', 'getEsgQuestionHierarchy', {
                year: scenario.baseline_year,
                hasanyesgs: true,
                siteIdList: scenario.site_filter,
                esgTagId: scenario.esg_tag_id !== 0 ? scenario.esg_tag_id : null,
            });
            const treeData = recursiveChildrenForEsgScenario({ children: res });

            await doSwaggerCall(
                'EsgScenarios',
                'editEsgScenario',
                { id: scenario.id },
                {
                    name: scenario.name,
                    targetHierarchy: scenario.target_hierarchy,
                    // only add new questions
                    newTarget: cutEsgTree(
                        treeData.children,
                        selectedTargetData.filter(
                            (value) => !questionList.some((q) => q.id === value.id)
                        )
                    ),
                }
            );
            reloadData();
            notifySuccess('ESG Question added to Scenario');
            showModal(MODAL_TYPE.NONE);
        } catch (error) {
            notifyError(error);
        }
    };

    return (
        <Dialog
            aria-labelledby="add-esg-question-modal"
            open
            maxWidth="sm"
            fullWidth
            onClose={() => showModal(MODAL_TYPE.NONE)}
        >
            <DialogTitle>Add ESG Questions to Scenario</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Select new ESG Questions to add to the Scenario
                </DialogContentText>
                {loading ? (
                    <Grid item xs={12}>
                        <CircularProgress size={20} />
                    </Grid>
                ) : (
                    <Grid item xs={12} sx={{ mt: 2 }}>
                        <Autocomplete
                            multiple
                            disableClearable
                            groupBy={(option) => groupQuestion(option)}
                            options={dataGroups}
                            getOptionDisabled={(option) =>
                                questionList.some((value) => value.id === option.id)
                            }
                            value={selectedTargetData.map((value) =>
                                dataGroups.find((e) => e.id === value.id)
                            )}
                            getOptionLabel={(option) => option?.name}
                            onChange={(ev, newValue) => {
                                const updatedValue = [
                                    ...newValue.filter(
                                        (item) => !questionList.some((q) => q.id === item.id)
                                    ),
                                    ...questionList,
                                ];
                                setSelectedTargetData(
                                    updatedValue.map((value) => ({
                                        type: 'question',
                                        id: value.id,
                                    }))
                                );
                            }}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip
                                        label={option?.name}
                                        {...getTagProps({ index })}
                                        disabled={questionList?.some(
                                            (value) => value?.id === option?.id
                                        )}
                                    />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField {...params} label="ESG Questions" />
                            )}
                        />
                    </Grid>
                )}
            </DialogContent>
            <DialogActions>
                <CommonButton
                    variant="inherit"
                    onClick={() => showModal(MODAL_TYPE.NONE)}
                    label="Cancel"
                    buttonType="secondary"
                />
                <CommonButton
                    label="Add Questions"
                    buttonType="primary"
                    onClick={saveScenario}
                    disabled={
                        selectedTargetData.filter(
                            (value) => !questionList?.some((q) => q?.id === value?.id)
                        )?.length === 0
                    }
                />
            </DialogActions>
        </Dialog>
    );
}

export default AddEsgQuestionToScenarioModal;
