import React from 'react';
import { Breadcrumbs, Grid, Typography } from '@mui/material';

function BreadCrumbTitle({ breadcrumbList }) {
    return (
        <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
                {breadcrumbList.map((breadcrumb, idx) => (
                    <Typography
                        key={idx}
                        variant="h5"
                        sx={{
                            fontWeight: 'bold',
                        }}
                    >
                        {breadcrumb.name}
                    </Typography>
                ))}
            </Breadcrumbs>
        </Grid>
    );
}

export default BreadCrumbTitle;
