import { Button, Grid, IconButton, TextField, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';

function NoteLineEdit({
    esgNoteEdit,
    setEsgNote,
    setEsgNoteEdit,
    id,
    esgNote = '',
    setShowEsgNote,
    currentPath,
    idx,
}) {
    const [noteValue, setNoteValue] = useState(esgNote);
    const esgNoteFieldRef = useRef();

    useEffect(() => {
        setNoteValue(esgNote);
    }, [esgNote]);

    useEffect(() => {
        if (esgNoteEdit === id) {
            esgNoteFieldRef.current?.focus();
        }
    }, [esgNoteEdit, id]);

    return esgNoteEdit === id ? (
        <TextField
            inputRef={esgNoteFieldRef}
            fullWidth
            size="small"
            autoComplete="off"
            label="Note"
            value={noteValue}
            onChange={(ev) => {
                const newValue = ev.target.value;
                setNoteValue(newValue);
                setEsgNote(currentPath, newValue, idx);
            }}
            onBlur={() => {
                if (noteValue.trim() === '') {
                    setShowEsgNote(false);
                }
                setEsgNoteEdit(false);
            }}
            sx={{
                ml: 2.75,
                mt: 1,
                mb: 0,
            }}
        />
    ) : (
        <>
            <Typography variant="body3">Note: {noteValue}</Typography>
            <IconButton size="small" onClick={() => setEsgNoteEdit(id)}>
                <EditIcon />
            </IconButton>
        </>
    );
}

const EsgLevelNote = ({
    idx,
    esgNoteEdit,
    item,
    showEsgNote,
    setShowEsgNote,
    setEsgNoteEdit,
    setEsgNote,
    currentPath,
}) => {
    return (
        <Grid
            item
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            sx={{
                pl: esgNoteEdit === item.id ? 0 : 2.5,
                pt: 2,
            }}
            style={{
                height: esgNoteEdit === item.id && showEsgNote ? '70px' : '35px',
                alignContent: 'center',
            }}
        >
            {!showEsgNote ? (
                <Button
                    variant="text"
                    size="small"
                    startIcon={<AddIcon />}
                    onClick={() => {
                        setShowEsgNote(true);
                        setEsgNoteEdit(item.id);
                    }}
                >
                    Note
                </Button>
            ) : (
                <NoteLineEdit
                    esgNoteEdit={esgNoteEdit}
                    setEsgNote={setEsgNote}
                    setEsgNoteEdit={setEsgNoteEdit}
                    id={item.id}
                    esgNote={item.esgNote}
                    setShowEsgNote={setShowEsgNote}
                    currentPath={currentPath}
                    idx={idx}
                />
            )}
        </Grid>
    );
};

export default EsgLevelNote;
