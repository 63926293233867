import React from 'react';
import {
    Paper,
    Table,
    TableBody,
    TableContainer,
    Grid,
    TableHead,
    TableRow,
    TableCell,
} from '@mui/material';
import { getSubTree } from '../../../helpers/rebalance';
import EsgScenarioLine from './EsgScenarioLine';
import BreadCrumbTitle from './BreadCrumbTitle';

export function getBreadcrumbFromPath(root, path) {
    const innerPath = [...path];
    const breadcrumb = [];
    let pointer = root;
    while (innerPath.length > 0) {
        pointer = pointer.children[innerPath.shift()];
        breadcrumb.push({
            name: pointer.name || 'Global',
        });
    }
    return { breadcrumbList: breadcrumb };
}

function EsgScenarioForm({ scenario, setScenario, question, path }) {
    const root = scenario?.target_hierarchy;

    const currentLevel = question;
    const { breadcrumbList } = getBreadcrumbFromPath({ children: root }, path);

    const setTarget = (location, updatedValues) => {
        const deepCopyTree = JSON.parse(JSON.stringify({ children: root }));
        const pointer = getSubTree(deepCopyTree, location);
        pointer.values = updatedValues;
        setScenario({ ...scenario, target_hierarchy: deepCopyTree.children });
    };

    const setEsgNote = (location, newEsgNote, idx) => {
        const deepCopyTree = JSON.parse(JSON.stringify({ children: root }));
        const pointer = getSubTree(deepCopyTree, [...location, idx]);
        pointer.esgNote = newEsgNote;
        setScenario({ ...scenario, target_hierarchy: deepCopyTree.children });
    };

    return (
        <>
            <Grid
                container
                item
                xs={12}
                justifyContent="space-between"
                spacing={0}
                sx={{ pb: 2, pt: 2 }}
                style={{ alignItems: 'center' }}
            >
                <BreadCrumbTitle breadcrumbList={breadcrumbList} />
            </Grid>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Question</TableCell>
                            <TableCell>Site</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {currentLevel.children.map((item, idx) => {
                            return (
                                <EsgScenarioLine
                                    key={item.id}
                                    item={item}
                                    idx={idx}
                                    setEsgNote={setEsgNote}
                                    currentPath={path}
                                    questionName={currentLevel.name}
                                    setTarget={(newValue) => {
                                        setTarget([...path, idx], newValue);
                                    }}
                                    baselineYear={scenario.baseline_year}
                                    targetYear={scenario.target_year}
                                />
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

export default EsgScenarioForm;
