import React, { useEffect, useState } from 'react';
import { Grid, IconButton, Typography } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useNavigate } from 'react-router-dom';
import useSorting from '../../../hooks/useSorting';
import { useApi } from '../../../hooks/useApi';
import { useGlobalFilters } from '../../../hooks/useGlobalFilters';
import useNotify from '../../../hooks/useNotify';
import ScenariosTable from './ScenariosTable';
import { TABLE_ROW_COUNT_DEFAULT } from '../../../helpers/constans';
import CommonButton from '../../../components/atom/CommonButton';

export default function ScenarioSection({
    search,
    baselineYear,
    targetYear,
    targetScopes,
    targetCategories,
    datalineTagId,
    siteFilter,
    sectionHeader,
    groupOpen,
    item,
    open,
    setOpen,
    groupby,
}) {
    const { notifyError } = useNotify();
    const { year } = useGlobalFilters();
    const navigate = useNavigate();
    const [selectMode, setSelectMode] = useState(false);
    const [selectedScenarios, setSelectedScenarios] = useState([]);
    const [{ limit, page }, setParams] = useState({ limit: TABLE_ROW_COUNT_DEFAULT, page: 0 });
    const { sort, sortDir, setSorting } = useSorting(['created_at', 'ASC']);
    let getScenariosParams = {
        search,
        limit,
        offset: page * limit,
        sort,
        sortDir,
    };
    if (groupby === 'TARGET' && item.id !== 0) {
        getScenariosParams = {
            ...getScenariosParams,
            baselineYear,
            targetYear,
            targetScopes,
            targetCategories,
            datalineTagId: parseInt(datalineTagId, 10) || 0,
            siteFilter,
        };
    }
    const [data, loading, error, setData, reloadData] = useApi('Scenarios', 'getScenarios', {
        ...getScenariosParams,
    });
    useEffect(() => {
        if (error) {
            notifyError(error);
        }
    }, [error, notifyError]);

    useEffect(() => {
        setParams({ page: 0, limit });
    }, [year, search]);

    return (
        <>
            <Grid container item xs={12} justifyContent="space-between" spacing={0}>
                <Grid item>
                    <Typography
                        variant="h4"
                        sx={{
                            cursor: groupby === 'TARGET' ? 'pointer' : '',
                        }}
                        onClick={() => {
                            if (groupby === 'TARGET') {
                                groupOpen.filter((idx) => idx !== item.id);
                                setOpen(!open);
                            }
                        }}
                    >
                        {sectionHeader}
                        {groupby === 'TARGET' && item.id !== 0 ? (
                            <IconButton>
                                <KeyboardArrowUpIcon />
                            </IconButton>
                        ) : null}
                    </Typography>
                </Grid>
                <Grid item>
                    <Grid
                        item
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        <Grid item>
                            <CommonButton
                                fullWidth
                                onClick={() => navigate('/scenarios/new')}
                                label="Add Scenario"
                                buttonType="primary"
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <ScenariosTable
                rows={data?.scenarios}
                limit={limit}
                page={page}
                setParams={setParams}
                total={data?.total}
                loading={loading}
                selectMode={selectMode}
                setSelectMode={setSelectMode}
                selectedScenarios={selectedScenarios}
                setSelectedScenarios={setSelectedScenarios}
                reloadData={reloadData}
                sort={sort}
                sortDir={sortDir}
                setSorting={setSorting}
                groupby={groupby}
            />
        </>
    );
}
