import React, { useCallback, useEffect, useState } from 'react';
import { doSwaggerCall, useApi } from '../../hooks/useApi';
import useNotify from '../../hooks/useNotify';
import { useGlobalFilters } from '../../hooks/useGlobalFilters';
import { useEsgFilters } from '../../hooks/useEsgFilters';
import EsgForm from './components/EsgForm';
import convertEsgTags from './helpers/convertEsgTags';

function EditEsgPage({
    newEsg,
    esgIdParam = false,
    reloadData,
    setEdited,
    esgQuestionId,
    sites,
    setGroupOpen,
}) {
    const esgId = esgIdParam;
    const [loading, setLoading] = useState(true);
    const [loadingEsg, setLoadingEsg] = useState(true);
    const { year, closedYear } = useGlobalFilters();
    const { esgQuestion } = useEsgFilters();
    const { notifySuccess, notifyError } = useNotify();

    // call getSitesForUsers for get site default_field
    const [siteData, loadingSiteData, errorSiteData] = useApi('Sites', 'getSitesForUsers', {
        pageSize: 1,
        idList: Array.isArray(sites) && sites.length === 1 ? sites[0] : null,
    });
    useEffect(() => {
        if (errorSiteData) {
            notifyError(errorSiteData);
        }
    }, [errorSiteData, notifyError]);

    const [esg, setEsg] = useState({
        company_id: 1,
        year,
        esg_question_id: undefined,
        esg_question: '',
        site_id: Array.isArray(sites) && sites.length === 1 ? sites[0] : null,
        values: [],
        notes: null,
        assigned_user: null,
        owner_user: null,
        documents: null,
        mark_completed: false,
        is_estimated: false,
        esgTags: [],
        from_date: null,
        to_date: null,
    });
    const [percentages, setPercentages] = useState([]);

    useEffect(() => {
        if (Array.isArray(sites) && sites.length === 1) {
            setEsg((oldEsg) => {
                return {
                    ...oldEsg,
                    site_default_field: siteData?.sites[0],
                };
            });
        }
    }, [siteData, setEsg]);

    useEffect(() => {
        // esgQuestionId from grouping when source filter is not selected
        if (!esgQuestion && !esgQuestionId) {
            setLoading(false);
            return;
        }
        setLoading(true);
        doSwaggerCall('EsgQuestions', 'getEsgQuestion', {
            id: esgQuestionId,
        })
            .then((s) => {
                setEsg((oldEsg) => {
                    // if the "old esg" value has anything, no need to override everything
                    if (oldEsg.esg_question !== '') {
                        return oldEsg;
                    }
                    return {
                        ...oldEsg,
                        esg_question_id: esgQuestionId,
                        esg_question: s,
                        // set esg's values to match the fields
                        values: s.fields.map((f) => {
                            return {
                                id: f.id,
                                value: '',
                                measure: f.defaultMeasure,
                                forceOverride: !!(f.ghg && f.ghg.length > 0), // if the field has GHG, forceOverride is true
                            };
                        }),
                    };
                });
                setLoading(false);
            })
            .catch(console.log);
    }, [esgQuestion, esgQuestionId, setLoading, setEsg]);

    const loadEsg = useCallback(() => {
        setLoadingEsg(true);
        doSwaggerCall('Esgs', 'getEsg', { id: esgId })
            .then((res) => {
                // getEsg does not return the actual esg question any more
                return doSwaggerCall('EsgQuestions', 'getEsgQuestion', {
                    id: res.esg_question_id,
                }).then((fullEsgQuestion) => {
                    setEsg({
                        id: res.id,
                        esg_question_id: res.esg_question_id,
                        esg_question: fullEsgQuestion,
                        year: res.year,
                        // set esg's values to match the fields
                        values: fullEsgQuestion.fields.map((f) => {
                            const value = res.values.find((v) => v.id === f.id);
                            return {
                                id: f.id,
                                value: value ? value.value : '',
                                measure: value?.measure || f.defaultMeasure,
                                forceOverride: value?.forceOverride || false,
                            };
                        }),
                        site_id: res.site_id === null ? 0 : res.site_id,
                        modified_user: res.modifiedUser,
                        owner_user: res.ownerUser,
                        assigned_user: res.assignedUser,
                        notes: res.notes,
                        documents: res.documents,
                        modified_at: res.modified_at,
                        mark_completed: res.mark_completed,
                        esgTags: res.esgTags.map((d) => d.name),
                        is_estimated: res.is_estimated,
                        from_date: res.from_date,
                        to_date: res.to_date,
                    });
                    setPercentages(res.esgTags.map((d) => d.percentage));
                    setLoadingEsg(false);
                });
            })
            .catch((err) => {
                setLoadingEsg(false);
                notifyError(err);
            });
    }, []);

    useEffect(() => {
        if (!newEsg) {
            loadEsg();
        } else {
            setLoadingEsg(false);
        }
    }, [esgId]);

    const onSubmit = async (values, { setSubmitting }) => {
        if (newEsg) {
            const req = doSwaggerCall(
                'Esgs',
                'addEsg',
                { dryRun: false },
                {
                    year: parseInt(values.year, 10),
                    esgQuestionId: values.esg_question_id,
                    siteId: values.site_id,
                    values: values.values,
                    notes: values.notes,
                    assignedToUserId: values.assigned_user?.user_id,
                    ownerUserId: values.owner_user?.user_id,
                    documentIdList: values.documents?.map((e) => e.id),
                    markCompleted: values.mark_completed,
                    esgTagList: convertEsgTags(values.esgTags, percentages),
                    isEstimated: values.is_estimated,
                    fromDate: values.from_date,
                    toDate: values.to_date,
                }
            );
            req.then(() => {
                setEdited(false);
                reloadData();
                notifySuccess('ESG added');
            }).catch((err) => {
                setEdited(false);
                reloadData();
                notifyError(err);
            });
        } else {
            const req = doSwaggerCall(
                'Esgs',
                'editEsg',
                { id: esgId },
                {
                    year: parseInt(values.year, 10),
                    esgQuestionId: values.esg_question_id,
                    siteId: values.site_id,
                    values: values.values,
                    notes: values.notes,
                    assignedToUserId: values.assigned_user?.user_id,
                    ownerUserId: values.owner_user?.user_id,
                    documentIdList: values.documents.map((e) => e.id),
                    markCompleted: values.mark_completed,
                    isEstimated: values.is_estimated,
                    esgTagList: convertEsgTags(values.esgTags, percentages),
                    fromDate: values.from_date || null,
                    toDate: values.to_date || null,
                }
            );
            req.then(() => {
                loadEsg();
                setGroupOpen((prev) => prev.filter((e) => e !== `${values.site_id}-site`));
                setSubmitting(false);
                setEdited(false);
                reloadData();
                notifySuccess('ESG updated');
            }).catch((err) => {
                notifyError(err);
                setSubmitting(false);
            });
        }
    };

    if (loading || loadingSiteData || loadingEsg) {
        return true;
    }

    return (
        <EsgForm
            esg={esg}
            onSubmit={onSubmit}
            newEsg={newEsg}
            setEdited={setEdited}
            percentages={percentages}
            setPercentages={setPercentages}
            closedYear={closedYear}
        />
    );
}

export default EditEsgPage;
