import { Button, Grid } from '@mui/material';
import React from 'react';
import AddIcon from '@mui/icons-material/Add';

const AddPlaceholder = ({ onClick, customStyle }) => {
    return (
        <Grid>
            <Button
                fullWidth
                variant="outlined"
                sx={{ height: customStyle?.height || '200px' }}
                onClick={onClick}
            >
                <AddIcon
                    sx={{
                        position: 'absolute',
                        height: customStyle?.iconHeight || '50px',
                        width: customStyle?.iconWidth || '50px',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                    }}
                />
            </Button>
        </Grid>
    );
};

export default AddPlaceholder;
