// function to filter target data by type
export function filterTargetDataByType(data, type) {
    return data.filter((item) => item.type === type).map((item) => item.id);
}

// function to filter target data to category
export function filterTargetDataToCategory(data) {
    const categoryIds = filterTargetDataByType(data, 'category');
    return [...new Set(categoryIds)];
}

// function to filter target data to subcategory
export function filterTargetDataToSubcategory(data) {
    const subcategoryIds = filterTargetDataByType(data, 'subcategory');
    return [...new Set(subcategoryIds)];
}

// function to filter target data to disclosure
export function filterTargetDataToDisclosure(data) {
    const disclosureIds = filterTargetDataByType(data, 'disclosure');
    return [...new Set(disclosureIds)];
}

// function to filter target data to question
export function filterTargetDataToQuestion(data) {
    const questionIds = filterTargetDataByType(data, 'question');
    return [...new Set(questionIds)];
}

// function to cut the tree based on selected target data
export function cutEsgTree(tree, selectedTargetData) {
    const selectedCategories = filterTargetDataToCategory(selectedTargetData);
    const selectedSubcategories = filterTargetDataToSubcategory(selectedTargetData);
    const selectedDisclosures = filterTargetDataToDisclosure(selectedTargetData);
    const selectedQuestions = filterTargetDataToQuestion(selectedTargetData);

    const newTree = tree
        .map((category) => {
            const newCategory = {
                ...category,
                children: category.children
                    .map((subcategory) => {
                        const newSubcategory = {
                            ...subcategory,
                            children: subcategory.children
                                .map((disclosure) => {
                                    const newDisclosure = {
                                        ...disclosure,
                                        children: disclosure.children
                                            .map((question) => {
                                                if (selectedQuestions.includes(question.id)) {
                                                    return question;
                                                }
                                                return null;
                                            })
                                            .filter((question) => question !== null),
                                    };
                                    if (
                                        selectedDisclosures.includes(disclosure.id) ||
                                        newDisclosure.children.length > 0
                                    ) {
                                        return newDisclosure;
                                    }
                                    return null;
                                })
                                .filter(
                                    (disclosure) =>
                                        disclosure !== null && disclosure.children.length > 0
                                ),
                        };
                        if (
                            selectedSubcategories.includes(subcategory.id) ||
                            newSubcategory.children.length > 0
                        ) {
                            return newSubcategory;
                        }
                        return null;
                    })
                    .filter(
                        (subcategory) => subcategory !== null && subcategory.children.length > 0
                    ),
            };
            if (selectedCategories.includes(category.id) || newCategory.children.length > 0) {
                return newCategory;
            }
            return null;
        })
        .filter((category) => category !== null && category.children.length > 0);
    return newTree;
}

// for ESG Question autocomplete
export function transformData(data) {
    const result = [];

    function traverseHierarchy(category) {
        const categoryInfo = { id: category.id, name: category.name };

        if (category.children) {
            category.children.forEach((subcategory) => {
                const subcategoryInfo = { id: subcategory.id, name: subcategory.name };

                if (subcategory.children) {
                    subcategory.children.forEach((disclosure) => {
                        const disclosureInfo = { id: disclosure.id, name: disclosure.name };

                        if (disclosure.children) {
                            disclosure.children.forEach((question) => {
                                result.push({
                                    id: question.id,
                                    name: question.name,
                                    fields: question.fields || [],
                                    category_id: categoryInfo.id,
                                    category_name: categoryInfo.name,
                                    subcategory_id: subcategoryInfo.id,
                                    subcategory_name: subcategoryInfo.name,
                                    disclosure_id: disclosureInfo.id,
                                    disclosure_name: disclosureInfo.name,
                                });
                            });
                        }
                    });
                }
            });
        }
    }
    data.forEach((category) => traverseHierarchy(category));
    return result;
}
